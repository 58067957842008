import { useState } from "react";
import "./App.css"; // Asegúrate de tener un archivo App.css para estilos

function App() {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const handleOnChange = (e) => setValue(e.target.value);

  const handleOnClick = () => {
    if (value.trim() !== "") {
      if (editIndex !== null) {
        // Si estamos editando, actualizamos el elemento
        const updatedList = [...list];
        updatedList[editIndex] = value;
        setList(updatedList);
        setEditIndex(null); // Salimos del modo de edición
      } else {
        // Si no estamos editando, agregamos una nueva tarea
        setList([...list, value]);
      }
      setValue("");
    }
  };

  const handleOnDelete = (index) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta tarea?")) {
      const newList = list.filter((_, i) => i !== index);
      setList(newList);
    }
  };

  const handleOnEdit = (index) => {
    setValue(list[index]);
    setEditIndex(index); // Guardamos el índice del elemento a editar
  };

  return (
    <div className="app-container">
      <h1>Lista de Tareas</h1>
      <div className="input-group">
        <input
          type="text"
          value={value}
          placeholder="Escribe tu tarea"
          onChange={handleOnChange}
        />
        <button onClick={handleOnClick}>
          {editIndex !== null ? "Actualizar Tarea" : "Agregar Tarea"}
        </button>
      </div>

      <ul className="task-list">
        {list.length > 0 ? (
          list.map((item, i) => (
            <li key={i} className="task-item">
              <span>{item}</span>
              <div className="task-actions">
                <button onClick={() => handleOnEdit(i)}>Editar</button>
                <button onClick={() => handleOnDelete(i)}>Eliminar</button>
              </div>
            </li>
          ))
        ) : (
          <p>No hay tareas. ¡Agrega una!</p>
        )}
      </ul>
    </div>
  );
}

export default App;


